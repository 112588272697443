import { ReactElement } from "react";
import Concerts from "../icons/Concerts";
import ConvenienceStore from "../icons/ConvenienceStore";
import Conventions from "../icons/Conventions";
import Malls from "../icons/Malls";
import NightClubs from "../icons/NightClubs";
import Restaurants from "../icons/Restaurants";
import SmallGroceries from "../icons/SmallGroceries";
import TouristAttractions from "../icons/TouristsAttractions";
import FeatureList from "@components/shared/featureList";

export default function FAQ(): ReactElement {
    const list = [
        {
            icon: <TouristAttractions />,
            title: "Tourist Attractions",
        },
        {
            icon: <Restaurants />,
            title: "Restaurants",
        },
        {
            icon: <Malls />,
            title: "Shopping Malls",
        },
        {
            icon: <Concerts />,
            title: "Outdoor Concerts",
        },
        {
            icon: <NightClubs />,
            title: "Night Clubs",
        },
        {
            icon: <SmallGroceries />,
            title: "Small Groceries",
        },
        {
            icon: <Conventions />,
            title: "Conventions",
        },
        {
            icon: <ConvenienceStore />,
            title: "Convenience Stores",
        },
    ];
    return (
        <FeatureList
            featureList={list}
            mainTitle="ATM Machine Buyer's Shopping Guide"
            mainDescription="With the surge in the use of debit cards, smart cards, and credit cards, the relevance of ATM machines remains steadfast. They provide a quick and convenient way to facilitate transactions at:"
            classes={{
                mainSectionClasses: "flex flex-col mx-auto my-10 md:my-32",
                mainTitleClasses:
                    "font-bold capitalize mb-2 md:w-9/12 mx-auto md:mb-4 text-2xl text-center  md:text-3xl",
                mainDescriptionClasses:
                    "mb-12 text-center capitalize text-lg md:text-xl",
                innerSectionClasses: "grid grid-cols-2 md:grid-cols-4 gap-16",
                oneBoxClasses: "flex flex-col items-center",
                titleClasses: "text-base text-center font-light",
                iconClasses: "text-xl md:text-2xl mb-2 font-semibold",
            }}
            colors={{
                mainDescriptionColor: "#777",

                titleColor: "#676767",
            }}
        />
    );
}
