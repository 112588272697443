import React from "react";

function Conventions() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
        >
            <mask
                id="mask0_4_123"
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="0"
                y="0"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M0 0H60V60H0z"></path>
            </mask>
            <g
                fill="#387BBD"
                clipPath="url(#clip0_4_123)"
                mask="url(#mask0_4_123)"
            >
                <path d="M17.15 55.364H4.636v-2.792a2.483 2.483 0 012.481-2.48h7.559a2.482 2.482 0 012.48 2.48v2.792h-.006zM7.788 43.966a3.1 3.1 0 013.106-3.083A3.1 3.1 0 0114 43.966a3.099 3.099 0 01-3.107 3.084 3.098 3.098 0 01-3.106-3.084zm6.883 4.49h-2.317a4.726 4.726 0 003.292-4.49 4.737 4.737 0 00-4.744-4.719 4.738 4.738 0 00-4.743 4.72 4.727 4.727 0 003.285 4.488H7.128a4.122 4.122 0 00-4.117 4.117v3.61a.818.818 0 00.818.82h14.15a.819.819 0 00.819-.82v-3.61a4.12 4.12 0 00-4.116-4.117h-.011zM36.258 55.364H23.743v-2.792a2.483 2.483 0 012.481-2.48h7.559a2.484 2.484 0 012.48 2.48v2.792h-.005zm-9.364-11.398A3.099 3.099 0 0130 40.883a3.1 3.1 0 013.108 3.083A3.1 3.1 0 0130 47.05a3.098 3.098 0 01-3.106-3.084zm6.883 4.49H31.46a4.727 4.727 0 003.285-4.49A4.736 4.736 0 0030 39.247a4.737 4.737 0 00-4.742 4.72 4.728 4.728 0 003.284 4.488h-2.316a4.122 4.122 0 00-4.117 4.117v3.61a.818.818 0 00.818.82h14.15a.818.818 0 00.818-.82v-3.61a4.122 4.122 0 00-4.117-4.117h-.002zM55.364 55.364H42.85v-2.792a2.483 2.483 0 012.48-2.48h7.56a2.482 2.482 0 012.479 2.48v2.792h-.005zM46 43.966a3.1 3.1 0 013.106-3.083 3.1 3.1 0 013.105 3.083 3.099 3.099 0 01-3.107 3.084 3.098 3.098 0 01-3.106-3.084H46zm6.883 4.49h-2.316a4.726 4.726 0 003.291-4.49 4.736 4.736 0 00-4.743-4.719 4.738 4.738 0 00-4.744 4.72 4.726 4.726 0 003.292 4.488h-2.316a4.122 4.122 0 00-4.117 4.117v3.61a.818.818 0 00.818.82h14.15a.819.819 0 00.818-.82v-3.61a4.12 4.12 0 00-4.116-4.117h-.017zM30 4.641a2.903 2.903 0 012.88 2.917A2.901 2.901 0 0130 10.475a2.903 2.903 0 01-2.882-2.917A2.904 2.904 0 0130 4.641zm0 7.47a4.54 4.54 0 004.51-4.553A4.54 4.54 0 0030 3.005a4.54 4.54 0 00-4.518 4.553A4.54 4.54 0 0030 12.111z"></path>
                <path d="M15.663 24.521h28.675l-1.635 3.061H17.306l-1.638-3.06h-.005zm8.725-7.696c0-1.647 1.106-2.987 2.463-2.987h6.292c1.362 0 2.471 1.341 2.471 2.987v6.06h-1.097v-1.257a.818.818 0 10-1.636 0v1.257h-5.76v-1.257a.818.818 0 10-1.637 0v1.257h-1.097v-6.06h.001zm18.803 12.393a.818.818 0 00.722-.431l2.515-4.697a.82.82 0 00-.318-1.098.823.823 0 00-.404-.107h-8.453v-6.06c0-2.55-1.843-4.624-4.108-4.624h-6.29c-2.26 0-4.1 2.072-4.1 4.624v6.06h-8.457a.82.82 0 00-.722 1.205l2.514 4.697a.818.818 0 00.721.431h1.098l3.323 15.794a.82.82 0 001.493.278.82.82 0 00.115-.615l-3.254-15.456h20.847l-3.252 15.456a.816.816 0 00.632.969.819.819 0 00.976-.632l3.305-15.794h1.097z"></path>
            </g>
            <defs>
                <clipPath id="clip0_4_123">
                    <path
                        fill="#fff"
                        d="M0 0H54V54H0z"
                        transform="translate(3 3)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default Conventions;
