import React from "react";

function TouristAttractions() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="62"
            height="62"
            fill="none"
            viewBox="0 0 62 62"
        >
            <mask
                style={{ maskType: "alpha" }}
                width="60"
                height="60"
                x="1"
                y="1"
                maskUnits="userSpaceOnUse"
            >
                <path fill="#D9D9D9" d="M1 1H61V61H1z"></path>
            </mask>
            <path
                fill="#387BBD"
                d="M2.725 3.45H13.43v3.07H7.014a.725.725 0 00-.725.726v52.029c0 .4.324.725.725.725h47.972a.725.725 0 00.725-.725V7.245a.725.725 0 00-.726-.724H48.57V3.45h10.705a.725.725 0 100-1.451H2.725a.725.725 0 100 1.45zM54.26 7.971V58.55H7.739V7.97h5.69v2.796a2.775 2.775 0 00-2.055 2.671 2.783 2.783 0 002.78 2.78 2.783 2.783 0 002.779-2.78c0-1.28-.875-2.35-2.054-2.671V7.97h32.24v2.796a2.776 2.776 0 00-2.053 2.671 2.783 2.783 0 002.778 2.78 2.783 2.783 0 002.78-2.78c0-1.28-.875-2.35-2.054-2.671V7.97h5.69zm-40.106 4.137a1.33 1.33 0 010 2.659 1.33 1.33 0 010-2.659zm33.69 0a1.33 1.33 0 010 2.659 1.33 1.33 0 010-2.659zm-.725-5.587H14.88V3.45h32.24v3.07z"
            ></path>
            <path
                fill="#387BBD"
                d="M28.052 30.312a.724.724 0 00-.726.725V53.78a.725.725 0 101.45 0V31.037a.724.724 0 00-.724-.725zM33.626 30.312a.724.724 0 00-.725.725V53.78a.725.725 0 101.45 0V31.037a.725.725 0 00-.725-.725z"
            ></path>
            <path
                fill="#387BBD"
                d="M41.968 20.272h-2.945V18.51h1.226a.725.725 0 00.725-.726v-3.444a.725.725 0 00-.725-.726H21.616a.725.725 0 00-.725.726v3.444c0 .401.324.726.725.726h1.225v1.76h-2.945a5.955 5.955 0 00-5.947 5.948 5.954 5.954 0 005.947 5.948 5.21 5.21 0 002.945-.88v22.494a.725.725 0 101.45 0V29.82a6.387 6.387 0 00.925-2.306h11.432c.17.87.49 1.647.925 2.306v23.96a.725.725 0 101.45 0V31.288a5.214 5.214 0 002.944.879 5.955 5.955 0 005.948-5.948 5.954 5.954 0 00-5.947-5.947zm-19.626-5.207h17.183v1.995H22.342v-1.995zm1.95 3.445h13.282v1.761H24.293v-1.76zm17.676 12.206c-2.055 0-3.558-1.445-3.92-3.696.024-.674.25-2.272 1.855-2.272 1.41 0 1.913 1.242 1.913 2.405a.725.725 0 101.45 0c0-2.306-1.35-3.855-3.363-3.855-1.645 0-2.827 1.08-3.184 2.767H25.147c-.357-1.687-1.544-2.768-3.195-2.768-2.01 0-3.362 1.55-3.362 3.856a.725.725 0 101.45 0c0-1.163.502-2.406 1.913-2.406 1.616 0 1.843 1.6 1.866 2.274-.362 2.25-1.865 3.695-3.92 3.695a4.502 4.502 0 01-4.497-4.497 4.502 4.502 0 014.497-4.498h22.07a4.503 4.503 0 014.498 4.498 4.503 4.503 0 01-4.499 4.497z"
            ></path>
        </svg>
    );
}

export default TouristAttractions;
